import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado
import './FloatingWhatsAppButton.css'; // Archivo CSS para los estilos

const FloatingWhatsAppButton = () => {
  //const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    const phone = "57 313 2903907".replaceAll(" ", "");
    const link = `https://wa.me/${phone}`;
    window.open(link, "_blank");
  };

  return (
    <div 
      className="floating-button" 
      onClick={handleClick}
      //onMouseEnter={() => setHovered(true)}
      //onMouseLeave={() => setHovered(false)}
    >
      <FaWhatsapp size={30} color="#fff" />
      {/*<span className={`hover-message ${hovered ? 'visible' : ''}`}>Chatea con nosotros</span>*/}
    </div>
  );
};

export default FloatingWhatsAppButton;