import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MarketPlacehome from "../../assets/img/Marketplace-morado.png";
import circle from "../../assets/img/circulogris.png";
import EcobolsaBlanco from "../../assets/img/EcobolsaBlanco.png";
import style from "./ShowCase.module.css";
import { CloseIcon, NextIcon } from "../../assets/img/icons";
import CardListProducts from "../../components/CardListProducts/CardListProducts";
import { Menu, MenuItem, Box, ThemeProvider } from "@material-ui/core";
import NestedMenuItem from "material-ui-nested-menu-item";
import "./ShowCase.css";
import useModal from "../../utils/hooks/useModal";
import modalStyle from "./ModalStyle.module.css";
import Input from "../../utils/Components/Input/Input";
import { Translate } from "../../language";
import "../../css/svg.css";
import "../../css/Text.css";

import lessCIrcle from "../../assets/img/lessCIrcle.png";
import marketplaceAdd from "../../assets/img/Marketplace-add.png";
import plusCircle from "../../assets/img/plusCircle.png";
import {
  addToBag,
  changeQuantity,
} from "../../redux/actions/MarketPlaceActions";
import styleCardList from "../../components/CardListProducts/CardListProducts.module.css";
import SearchIcon from '@mui/icons-material/Search';
import imagen_instruction from "../../assets/img/imagen_in.svg";


const ShowCase = ({
  pointsBuy,
  ecoPrice,
  bestSellerProducts,
  consolidateProducts,
  setPage,
  categories,
  setSkipConsolidateProducts,
  pageSkip,
  pointsToRedeem,
  searchState,
  setSearchState,
  categoryState,
  setCategoryState,
  subCategoryState,
  setSubCategoryState,
}) => {
  const { bag } = useSelector((state) => state.marketPlace);

  const [product, setProduct] = useState([]);
  const [ref, isShow, setIsShow] = useModal(false);

  const [searchMenuPosition, setSearchMenuPosition] = useState(null);

  const handleSearchClick = (event) => {
    console.log(event.currentTarget.getBoundingClientRect().top);
    setSearchMenuPosition({
      top: event.currentTarget.getBoundingClientRect().top + 40,
      left: event.currentTarget.getBoundingClientRect().left,
    });
  };

  const handleSearchClose = () => {
    setSearchMenuPosition(null);
  };

  const handleCategory = (categoryId) => {
    setCategoryState(categoryId);
    setSubCategoryState("");
  };

  const handleSubCategory = (categoryId, subCategoryId) => {
    setCategoryState(categoryId);
    setSubCategoryState(subCategoryId);
  };

  useEffect(() => {
    if (isShow) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }

    // Limpia el estilo cuando el componente se desmonta
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isShow]);

  return (
    <div 
  
    className={`showCase-container`}>
      <div className="showCase-title-container">
        <div className="showCase-icon-container">
          <img src={MarketPlacehome} height={30} />
        </div>

        <div className="showCase-title">{Translate("MarketPlace", "es")}</div>
        
        <div className="showCase-shoppingBag">
          <div className="spreader-bar-navbar"> </div>

          <Input
            type="text"
            placeholder={"Buscar producto"}
            name="searchInput"
            value={searchState}
            classNameInput={`width-200 text-center color-purple-dark ${style.search_button}`}
            onChange={(e) => {
              setSkipConsolidateProducts(0);
              setSearchState(e.target.value);
            }}
          />
          <div className="spreader-bar-navbar"> <SearchIcon color="secondary" pading-right="-10px" /> </div>

          <NestedMenuItem
            id="basic-button"
            parentMenuOpen={Boolean(searchMenuPosition)}
            label={"Categorías"}
            onClick={handleSearchClick}
            aria-controls={
              Boolean(searchMenuPosition) ? "basic-menu" : undefined
            }
            aria-haspopup="true"
            aria-expanded={Boolean(searchMenuPosition) ? "true" : undefined}
          />
          <Menu
            id="basic-menu"
            anchorReference="anchorPosition"
            anchorPosition={searchMenuPosition}
            open={Boolean(searchMenuPosition)}
            onClose={handleSearchClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {categories.map((category, index) => (
              <Box
                sx={{
                  color: "var(--tittle-color)",
                }}
              >
                <NestedMenuItem
                  key={index}
                  label={category.name}
                  parentMenuOpen={Boolean(searchMenuPosition)}
                  onClick={() => {
                    handleSearchClose();
                    handleCategory(category._id);
                  }}
                  value={category._id}
                >
                  {category.subCategoriesIds?.map(
                    (subCategory, indexSubCategory) => (
                      <Box
                        sx={{
                          color: "var(--tittle-color)",
                        }}
                      >
                        <MenuItem
                          key={indexSubCategory}
                          onClick={() => {
                            handleSearchClose();
                            handleSubCategory(category._id, subCategory._id);
                          }}
                        >
                          {subCategory.name}
                        </MenuItem>
                      </Box>
                    )
                  )}
                </NestedMenuItem>
              </Box>
            ))}

            <Box
              sx={{
                color: "var(--secondary-color)",
                fontSize: "0.9rem",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleSubCategory("", "");
                }}
              >
                Limpiar filtro
              </MenuItem>
            </Box>
          </Menu>

          <div className="spreader-bar-navbar"></div>
          {localStorage.getItem("typeProfile") == "franchise" && (
            <>
              <Link className="link-style" to="/MarketPlace/Points">
                {Translate("ecoPoints", "es")}
              </Link>
              {/* <EcoPointsStarIcon height={37} /> */}
              <div className="link-style">{pointsBuy}</div>

              <div className="spreader-bar-navbar"></div>
            </>
          )}

      
        </div>
      </div>

      <div className={`${style.card_container}`}>
        {!categoryState && !searchState && (
          <>
            <div className={`${style.showcase_subtitle} margin-top-10vh`}>
              {Translate("BestSeller", "es")}
            </div>
            <div className={`${style.bestseller_content_grid}`}>
              <div
                className="icon-container icon-container-left"
                onClick={() => {
                  setPage((prev) => {
                    if (prev > 0) {
                      return prev - 1;
                    } else {
                      return 0;
                    }
                  });
                }}
              >
                <NextIcon
                  className="cls-back-next back-svg"
                  height="22"
                  width="15"
                />
              </div>
              <div className={`${style.bestseller_content}`}>
                {bestSellerProducts.map((product) => (
                  <CardListProducts
                    key={product.id}
                    maxQuantity={1000}
                    productBag={
                      bag.filter((item) => item._id == product._id)[0]
                    }
                    product={product}
                    pricePoints={product.price / ecoPrice}
                    picture={
                      product && product.image && product.image.url.small
                    }
                    text={product.name}
                    onClickPicture={() => {
                      setProduct(product);
                      setIsShow(true);
                    }}
                  />
                ))}
              </div>
              <div
                className="icon-container icon-container-right"
                onClick={() => {
                  setPage((prev) => {
                    if (bestSellerProducts.length > 1) {
                      return prev + 1;
                    } else {
                      return prev;
                    }
                  });
                }}
              >
                <NextIcon className="cls-back-next" height="22" width="15" />
              </div>
            </div>
          </>
        )}

        <div className="showCase-subTitle margin-top-10vh">
          Productos
        </div>
        <div className={`${style.product_content_grid}`}>
          <div className={`${style.products_content}`}>
            {consolidateProducts.map(({ products, stocks, _id }, index) => (
              <CardListProducts
                zIndex={50 - index}
                key={_id}
                maxQuantity={stocks}
                productBag={
                  bag.filter((item) => item._id == products[0]._id)[0]
                }
                product={products[0]}
                pricePoints={products[0].price / ecoPrice}
                picture={
                  products[0] &&
                  products[0].image &&
                  products[0].image.url.small
                }
                text={products[0].name}
                onClickPicture={() => {
                  setProduct({
                    ...products[0],
                    inventory:{
                      stocks
                    }
                  });
                  setIsShow(true);
                }}
              />
            ))}
          </div>
        </div>
        <div className={`${style.nav_button_container}`}>
          <div
            onClick={() => {
              setSkipConsolidateProducts((skipConsolidateProducts) => {
                if (skipConsolidateProducts - 25 > 0) {
                  return skipConsolidateProducts - 25;
                }
                return 0;
              });
            }}
            className={`${style.nav_button}`}
          >
            <NextIcon
              className="cls-back-next back-svg"
              height="22"
              width="15"
            />
          </div>
          <div
            onClick={() => {
              setSkipConsolidateProducts((skipConsolidateProducts) => {
                if (consolidateProducts.length == 25) {
                  return skipConsolidateProducts + 25;
                }
                return skipConsolidateProducts;
              });
            }}
            className={`${style.nav_button}`}
          >
            <NextIcon className="cls-back-next" height="22" width="15" />
          </div>
        </div>
        
      </div>

      <Modal
        refe={ref}
        closeModal={() => {
         
          setIsShow(false);
        }}
        productBag={bag.find((item) => item._id == product._id)}
        maxQuantity={product.inventory ? product.inventory.stocks : 0}
        displayModal={isShow}
        modalStyle={modalStyle}
        circle={circle}
        product={product}
      />
    </div>
  );
};

const Modal = ({
    refe,
  closeModal,
  displayModal,
  modalStyle,
  product,
  circle,
  productBag,
  maxQuantity,
}) => {
  const [quantity, setQuantity] = useState(
    productBag ? productBag.quantity : 1
  );
  const dispatch = useDispatch();
  const divStyle = {
    display: displayModal ? "block" : "none",
  };
  const close = (e) => {
    e.stopPropagation();
    closeModal(e);
  };

  const onClickPlusBtn = () => {
    if (maxQuantity > quantity) {
      setQuantity(parseInt(quantity) + 1);
    }
  };
  const onClickMinusBtn = () => {
    if (quantity >= 1) {
      setQuantity(parseInt(quantity) - 1);
    }
  };

  return (
    <div className={`${modalStyle.background_container}`} style={divStyle}>
    
      <div ref={refe}  className={`${modalStyle.modal_content}`}>
      <div
      className="ms-auto"
      onClick={closeModal}
      >
 
      <CloseIcon height={20}/>
      </div>
        <div className={`${modalStyle.product_name}`}>{product?.name} </div>
        <div className={`${modalStyle.information_content}`}>
          <div className={`${modalStyle.content_img}`}>
            <img
              alt="img"
              className={`${modalStyle.img_product}`}
              onError={(e) => (e.target.src = circle)}
              src={product?.image?.url.small}
            ></img>
          </div>
          
          
          <div className={`${modalStyle.content_description}`}>
          <div className={`${modalStyle.description_title}`}>Características</div>
          {product.image && (
           <div className={`${modalStyle.image_container}`}>
             <img
               src={imagen_instruction}
               alt="Instrucciones de uso"
                className={`${modalStyle.instruction_image}`}
             />
          </div>  )}

            <div className={`${modalStyle.description_title}`}>Descripción</div>
            <div className={`${modalStyle.description_text}`}>
            {product?.description}
            </div>
   
            {product?.instructions && (
              <div className="mt-3">
                <div className={`${modalStyle.description_title}`}>
                  Instrucciones de uso
                </div>
                <div className={`${modalStyle.description_text}`}>
                  {product.instructions}
                </div>
              </div>
            )}

            {product?.ingredients && (
              <div className="mt-3">
                <div className={`${modalStyle.description_title}`}>
                  Ingredientes
                </div>
                <div className={`${modalStyle.description_text}`}>
                  {product.ingredients}
                </div>
              </div>
            )}

            {product?.productsize && (
              <div className="mt-3">
                <div className={`${modalStyle.description_title}`}>Tamaño</div>
                <div className={`${modalStyle.description_text}`}>
                  {product.productsize}
                </div>
              </div>
            )}
          </div>
          <div className={`${styleCardList.quantity_container_in_modal}`}>
            <span>
              <div className={`${styleCardList.btn_quantity_container}`}>
                <div className={`${styleCardList.quantity_product_warning}`}>
                  Stock: {maxQuantity}
                </div>

                <div className={`${styleCardList.quantity_product_container}`}>
                  <img
                    className={`${styleCardList.quantity_icon}`}
                    onClick={() => {
                      onClickMinusBtn();
                    }}
                    src={lessCIrcle}
                    alt=""
                  />
                  <input
                    type={"text"}
                    value={quantity}
                    // name={"quantityDeleted"}
                    onChange={(e) => {
                      if (Number.isInteger(parseInt(e.target.value, 10))) {
                        if (
                          e.target.value <= maxQuantity &&
                          e.target.value >= 0
                        ) {
                          setQuantity(e.target.value);
                        }
                      } else if (e.target.value == "") {
                        setQuantity(e.target.value);
                      }
                    }}
                    className={`${styleCardList.quantity_product}`}
                  />
                  <img
                    className={`${styleCardList.quantity_icon}`}
                    onClick={() => {
                      onClickPlusBtn();
                    }}
                    src={plusCircle}
                    alt=""
                  />
                </div>

                <div className={`${styleCardList.quantity_product_title}`}>
                  Cantidad
                </div>
              </div>
            </span>
            <div>
              <button
                className={`${styleCardList.marketplace_add} animate__animated animate__bounceIn`}
                onClick={() => {
                  dispatch(addToBag(product));
                  dispatch(changeQuantity(product, quantity));
                }}
              >
                <img  style={{height:"30px"}} src={marketplaceAdd} alt="Agregar" />
                <span className={`${styleCardList.marketplace_add_text}`}>Agregar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowCase;
