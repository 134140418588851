import React from 'react'
import './style.css'
import imgAbout from '../../assets/img/img-about.svg'
import { Col, Container, Row } from 'react-bootstrap'

import iconRecycle from '../../assets/img/N-Historia.png'
import iconMegaphone from '../../assets/img/N-Identidad.png'
import Section from '../Section/Section'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import heart from '../../assets/img/icons/heart.svg'
import planet from '../../assets/img/icons/planet.svg'
import achievement from '../../assets/img/icons/achievement.svg'
import About_Carousel from '../Carousel/ACarousel'

export const AboutUs = () => {
  return (
    <div className='about-us-container'>
      <Section 
        className='section-title'
        text2={'SOMOS ECOIDEAS'} 
      />

      <About_Carousel/>
      
      <div className='about-content'>
      <img src={iconRecycle} alt='icono de telefono celular' className={'icon-size-img1'} />
        <div className='about-item'>
        <div className='center-about'>
          <h5 className='about-title'> HISTORIA</h5>
              <p className='paragraphs'>En septiembre de 2020, es fundada por cuatro emprendedores comprometidos
                    con la idea de cuidar el planeta, ECO INDUSTRIA DE INNOVACIÓN DESARROLLO 
                    Y GESTIÓN AMBIENTAL S.A.S. BIC “ECOIDEA FOR YOU S.A.S. BIC” en Guachené, Cauca,
                    y con el objetivo claro de la ejecución de un modelo de economía circular,
                    en octubre de 2021, se da un paso más con la constitución de ECO COMERCIALIZADORA
                    DE INNOVACION DESARROLLO Y GESTION AMBIENTAL S.A.S. BIC, “ECOIDEA STORES S.A.S. BIC”,
                    en Candelaria, Valle, consolidando así el grupo empresarial ECOIDEAS.
              </p>

              <Link onClick={() => {
                window.scrollTo(0, 0)
              }} to='/our-history' style={{ textDecoration: 'none' }}>
                <Button texto={'Ver más'} estilo={{ backgroundColor:'#a3d8b4', border: 'aqua' }} />
              </Link>
         </div>
      </div>
     </div>

      <div className='about-content'>
        <img src={iconMegaphone} alt='icono de telefono celular' className={'icon-size-img2'} />
          <div className='about-item'>
          <div className='center-about'>
              <h5 className='about-title'>IDENTIDAD</h5>
              <p className='paragraphs'>
                Ecoideas es la suma de aquellos que creen que el cambio es posible,
                de los apasionados que entienden que cuando se trabaja en los sueños
                estos se hacen realidad, de los que saben que no existen imposibles y
                de aquellos que actúan coherentemente para lograr aquí y ahora un
                planeta mejor y más limpio.
              </p>
              <Link to='/our-identity' style={{ textDecoration: 'none' }}
              onClick={() => {
                window.scrollTo(0, 0)
              }}
              >
                <Button texto={'Ver más'} onClick={()=>{}} estilo={{ backgroundColor:'#a3d8b4', border: 'aqua' }}/>
              </Link>
           
          </div>  
        </div>
      </div>
      
      
   
      <Section text2={'VALORES CORPORATIVOS'} />
    
      <div className='values-container'>
        <Container className='values-container-info'>
          <Row>
              <Col className='center-about'>
                <div className='icon-container-plat'>  
                    <img src={achievement} alt='icono de telefono celular' className={'icon-size'} />
                </div>
                <h5 className='about-title'>HONESTIDAD</h5>
                <p>Mantener la propuesta de valor y actuar coherentemente en busca del desarrollo social y sostenible del país.<br/>
                </p>
              </Col>
              <Col className='center-about'>
             
                  <div className='icon-container-plat'>
                    <img src={planet} alt='icono de telefono celular' className={'icon-size'} />
                  </div>
                  <h5 className='about-title'>RESPONSABILIDAD</h5>
                  <p>Impactar positivamente el planeta y la sociedad con nuestras decisiones, respetando la gente, los valores éticos, la comunidad y el medio ambiente, contribuyendo así a la construcción del bien común.
                  </p> 
               
              </Col>
              <Col className='center-about'>
                <div className='icon-container-plat'>
                  <img src={heart} alt='icono de telefono celular' className={'icon-size'} />
                </div>
                <h5 className='about-title'>TRASPARENCIA</h5>
                <p className='paragraphs'>Ofrecer herramientas e información para la elección de las mejores opciones en cosmética e higiene del hogar.
                </p> 
              </Col>
          
          </Row>
       </Container>
      </div>
      
     
    </div>
  );

};
export default AboutUs;