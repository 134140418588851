import React, { useEffect, useRef, useState } from 'react'
import Section from '../Section/Section'
import { Button } from '../Button/Button'
import imgSerEcomaster from '../../assets/img/como-ser-ecotienda.svg'
import { Col, Container, Row  } from 'react-bootstrap'
import '../Ecomaster/style.css'
import { useNavigate } from "react-router-dom";
import { EcotiendaSteps } from '../EcotiendaSteps/EcotiendaSteps';
import ProgressSteps from './ProgressSteps'


export const JoinUs = () => {
  const history = useNavigate()
  const [isOpenSteeps, setIsOpenSteeps] = useState(false)
  const buttonRef = useRef(null);
  useEffect(() => {
    
    if (isOpenSteeps && buttonRef.current) {
      setTimeout(() => {
        buttonRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);

   
    }
    return () => {
      
    }
  }, [isOpenSteeps])
  
  return (
    <div className='JoinUs-container'>
      <Section 
       className='section-title'
       text2={'¿CÓMO SER ECOTIENDA?'}/>

      <div>
        <img 
          src={imgSerEcomaster} 
          alt="imagen de como ser ecomaster"
          className="img-joint-us"
          />
      </div>

          
      <div className='info-container' style={{paddingTop:'0px', paddingBottom:'20px'}}>
  
          <div> <p> <strong style={ { padding: '16px',  
                    fontFamily: 'Montserrat', 
                    fontSize: 'x-large', 
                    fontWeight:'500'} } 
                    className='center-elements'
                    >
                      Requerimientos</strong>

                <ul>
                  <li>Local propio o alquilado con contrato</li>
                  <li>Capital de 50’000,000 COP</li>
                  <li>Área mínima 50 metros cuadrados</li>
                  <li>Contar con un vehículo</li>
                  <li>Experiencia comercial</li>
                  <li>Estudios de básica primaria como mínimo</li>
                  <li>2 referencias personales y 1 bancaria</li>
                  <li>Celular y correo electrónico activos</li>
                  <li>Usuario de redes sociales y buenas relaciones interpersonales</li>
                  <li>¡Tener disponibilidad de tiempo, disciplina y actitud!</li>
                </ul>

                <strong 
                style={ { padding: '16px',  
                          fontFamily: 'Montserrat',
                          fontSize: 'x-large', 
                          fontWeight:'500'} } 
                          className='center-elements'
                          >
                            Pasos a seguir</strong>
                <ul>     
                  <li>Registrarse en la aplicación de Ecoideas</li>
                  <li>Nombre, apellido, celular, correo, fecha de nacimiento, cómo nos conociste / cómo llegaste a nosotros</li>
                  <li>Anexar copia de la cédula en formato PDF</li>
                  <li>Anexar copia del contrato de arrendamiento (si lo hay)</li>
                  <li>Dirección completa de local, ciudad y departamento</li>
                  <li>Foto a color reciente</li>
                  <li>Referencias personales escritas y verificadas</li>
                  <li>Realizar una entrevista personal y/o videollamada 
                      (en esta se revisará el cumplimiento de los requerimientos previamente mencionados)</li>
                  <li>Video de 30 segundos sobre por qué quieres abrir una Ecotienda Biotú</li>   
                </ul>  
              </p>
            </div>
      
    
         
          <div className='align-buttons'
            style={{paddingTop:'5px', paddingBottom:'30px'}}>
              <Button 
                onClick={() => { setIsOpenSteeps(true)
                
                  if (isOpenSteeps && buttonRef.current) {
                  
                  buttonRef.current.scrollIntoView({ behavior: 'smooth' });
                   }  
                  }}
                
                  estilo={{ border: '5px solid black;', 
                            maxwidth: '270px', fontText:'8px', minwidth: '170px'
                          }}
                  texto={'CONSULTA LA GUÍA PASO A PASO'} />
                  
                  </div>
                  <div ref={buttonRef} ></div>
              
              {
              isOpenSteeps &&
              
              <ProgressSteps />
              }
           
      </div>
    </div>        
   
  )
}
export default JoinUs