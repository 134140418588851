import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../Aside/style.css'
import factoryIcon from '../../assets/img/factory-icon.svg'
import lightBulb from '../../assets/img/lightbulb-icon.svg'
import likeIcon from '../../assets/img/like-icon.svg'
export const Aside = () => {
    return ( 
        <div className='aside-container'>  
          <Container className='aside-container-info'>
            <Row>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={lightBulb} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>INNOVACIÓN</h5>
                    <p>Materializamos la creatividad en avances tecnológicos en la industria de la higiene y cosmética, desafiando los métodos convencionales para cuidar el planeta.</p>
                </Col>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={likeIcon} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>CALIDAD</h5>
                    <p>Estamos comprometidos contigo, por eso, cumplimos con los estándares más altos de calidad. Perseguimos permanentemente mejorar nuestros procesos productivos y la forma de llegar a tu hogar, sin abandonar nuestro compromiso de cuidado con el planeta.
                    </p>
                </Col>
                <Col className='position-col'>
                    <div className='background-rounded'>
                        <img src={factoryIcon} alt='' className='size-icons'></img>
                    </div>
                    <h5 className='advantage_title'>SOSTENIBILIDAD</h5>
                    <p>Nuestros productos son biodegradables, no alteran el pH del agua y se degradan naturalmente, tienen origen vegetal, no hacemos pruebas en animales, cuentan con agradables fragancias veganas y son libres de fosfatos nocivos para la vida acuática.</p>
                </Col>
            </Row>
        </Container>
        </div>
    );
};
export default Aside;