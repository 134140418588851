import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import '../Footer/style.css';
import logoWhite from '../../assets/img/logo-purple.svg'
import phoneIcon from '../../assets/img/icons/phone.svg'
import facebookIcon from '../../assets/img/icons/facebook-icon.svg'
import tiktokIcon from '../../assets/img/icons/tiktokIcon.svg'
import instagramIcon from '../../assets/img/icons/instagram-icon.svg'
import EcoideasIcon from '../../assets/img/icons/icon-logo.png'
import coral from '../../assets/img/icons/coral-icon.svg'
import { Button } from '../Button/Button';
//import doc1 from '../../docs/Garantia-cambios-y-devol.pdf'

import gobiernocorp1 from'../../docs/CODIGO_DE_ETICA_Y_BUEN_GOBIERNO_CORPORATIVO.pdf';
import terminos1 from '../../docs/TERMINOS_Y_CONDICIONES_DE_USO_DEL_SITIO_ECOIDEAS.pdf'
import policie1 from '../../docs/POLITICAS_DE_PROTECCION_DE_DATOS_PERSONALES.pdf'

export const Footer = () => {
    
    const downloadCorpFiles = (corps) => {
        corps.forEach(corpFile => {
            const link2 = document.createElement('a');
            link2.href = corpFile;
            link2.download = corpFile.substring(corpFile.lastIndexOf('/') + 1);
            link2.click();
        });
    }

    const corpFiles = [
    gobiernocorp1
    ];

    const downloadPolicies = (policies) => {
        policies.forEach(policyFile => {
            const link3 = document.createElement('a');
            link3.href = policyFile;
            link3.download = policyFile.substring(policyFile.lastIndexOf('/') + 1);
            link3.click();
        });
    }
    
    const policiesFiles = [
        policie1
    ];

    const downloadTerminos = (terminos) => {
        terminos.forEach(terminosFile => {
            const link4 = document.createElement('a');
            link4.href = terminosFile;
            link4.download = terminosFile.substring(terminosFile.lastIndexOf('/') + 1);
            link4.click();
        });
    }
    const terminosFiles = [
        terminos1
    ];
    const getOS = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detectar Android
        if (/android/i.test(userAgent)) {
            return 'android';
        }

        // Detectar iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'ios';
        }

        return 'unknown';
    };

    const handleButtonClick = () => {
        const os = getOS();
        const ecoideasUrl = os === 'android' ? 'https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es' : os === 'ios' ? 'https://apps.apple.com/mx/app/ecoideas-app/id6475202942' : 'https://ecoideas.app';
        window.location.href = ecoideasUrl;
    };

    return (
        <> {/* <Button
            onClick={() => {
                const phone = "57 321 8190490".replaceAll(" ","")
                const link = `https://wa.me/${phone}`
                window.open(link, "_blank")
                //https://wa.me/573218190490
            }}
            style={{
                position: "relative",
                top: "25px",
                right: "25px",
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor:'#a3d8b4'
            }} texto={'CHATEA CON NOSOTROS'}
            estilo={{
                backgroundColor: '#a3d8b4',
                border: '#a3d8b4',
                display: 'flex',
                flexDirection: 'row',
                padding: '0%',
                justifyContent: 'center'
            }}
            icon={coral} />
        */}

            <div className='container-footer'>

                <div className='d-flex flex-column flex-lg-row gap-5 justify-content-center'>
                       
                        <div className='d-flex flex-column '>   
                            <div className='icon-footer'>
                            <img width={150} src={logoWhite} alt=''></img>
                            </div>
                            <p className='icon-text'>Somos la suma de aquellos que creen que el cambio es posible,<br />
                            de los apasionados que trabajan para hacer los sueños realidad.</p>
                        </div>
                       

                        <div className='d-flex flex-column  '>
                            <h6 className='titles'>CONTÁCTANOS</h6>
                            <ul>
                                <li className='li-fone'> <img src={phoneIcon} alt='' className='size-icons-footer'></img> (314) 810-1312</li>
                                <li className='li-fone'> <img src={phoneIcon} alt='' className='size-icons-footer'></img> (314) 810-5161</li>
                                <li className='li-fone'> servicioalcliente@biotu.com.co</li>
                            </ul>
                            
                        </div>
                       
                       
                        <div className='d-flex flex-column  '>
                           
                            <h6 className='titles'>TERMINOS LEGALES</h6> 
                           <lu>
                           <li 
                                className='li-fone'
                                onClick={() => downloadCorpFiles(corpFiles)}>
                                Código De Ética Y Buen Gobierno Corporativo
                                <br/></li>   
                            <li
                                className='li-fone'
                                onClick={() => downloadPolicies(policiesFiles)}>
                                Política De Protección De Datos Personales
                                <br/> </li> 
                            <li
                                className='li-fone'
                                onClick={() => downloadTerminos(terminosFiles)}>
                                Términos Y Condiciones De Uso<br/> </li> 
                                
                             </lu>
                        </div>
                        
                        
                        <div className='d-flex flex-column '>
                            <h6 className='titles'>SÍGUENOS</h6>
                            <div>
                                <a href="https://www.facebook.com/people/biotu/100064726338868/">
                                    <img src={facebookIcon}
                                        alt=''
                                        className='size-social-icons-footer space-between-icons'></img>
                                </a>

                                <a href="https://www.tiktok.com/@bio.tu?_t=8jL9NIEVm1W&_r=1">
                                    <img src={tiktokIcon}
                                        alt=''
                                        className='size-social-icons-footer space-between-icons rounded-2xl'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            height: '25px',
                                            filter: 'invert(83%) sepia(11%) saturate(3772%) hue-rotate(291deg) brightness(116%) contrast(99%);'
                                        }}
                                    ></img>
                                </a>

                                <a href="https://www.instagram.com/bio.tu/?igsh=YmY4M2g5N2Izd3E%3D">
                                    <img src={instagramIcon}
                                        alt=''
                                        className='size-social-icons-footer space-between-icons'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            height: '25px',
                                            filter: 'invert(83%) sepia(11%) saturate(3772%) hue-rotate(291deg) brightness(116%) contrast(99%);'
                                        }}></img>
                                </a>
                                <a onClick={handleButtonClick} >
                                    <img src={EcoideasIcon}
                                        alt=''
                                        className='size-social-icons-footer space-between-icons'
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            height: '25px',
                                            filter: 'invert(83%) sepia(11%) saturate(3772%) hue-rotate(291deg) brightness(116%) contrast(99%);'
                                        }}>
                                    </img>
                                </a>             
                            </div>
                        </div> 
                  </div>                           
            </div>
        </>
    )
}
