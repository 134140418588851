import React from 'react'
import { Button } from '../Button/Button'
import Section from '../Section/Section'
import iconWeb from '../../assets/img/icon-web.svg'
import iconMovil from '../../assets/img/icon-movil.svg'
import googlePlay from '../../assets/img/google-play-badge.png'
import appleStore from '../../assets/img/apple-store.svg'
import '../OurPlatforms/style.css'
import { Col, Container, Row } from 'react-bootstrap'
import './style.css'
export const OurPlatforms = () => {
  return (
  
      <div  className='OurPlatforms-container'>
        <Section
          className='section-title'
          text2={'PLATAFORMAS'}
        />

        <div className='info-container-text'>
          <p>Ofrecemos plataformas seguras e intuitivas, dedicadas a la comercialización de
            productos biodegradables de alta calidad, fomentamos la creación de comunidad
            para apalancar tu crecimiento</p>
        </div>

      
   

          <div className='info-container' >
            <div className='sections text-center'>
              <div className='icon-container-plat'>
                <img src={iconWeb} alt='icono de navegación web' className='icon-size' />
              </div>
              <h4 className='text-secondary text-medium color-black'>Aplicación Web</h4>
            </div>
         
         
              <p style={{padding:'16x 0 0 0'}}>Promovemos la conciencia ambiental y la adecuada
                disposición de residuos, brindando información y recursos que fomenten prácticas
                sostenibles para contribuir a la conservación del planeta.
              </p>
              <a href="https://ecoideas.app" className="primary-button" aria-label="Ir a la aplicación web">
                Ir a la aplicación web
              </a>
           

          </div>

 

        

          <div  className='info-container'>
              <div className='sections text-center'>
                <div className='icon-container-plat'>
                  <img src={iconMovil} alt='icono de teléfono celular' className='icon-size' />
                </div>
                <h4 className='text-secondary text-medium color-black'>Aplicación Móvil</h4>
              </div>
                  <p style={{padding:'16px 0 0 0'}}>Facilitamos la conexión entre personas interesadas en el medio ambiente,
                    proporcionando un espacio virtual donde puedan compartir conocimientos, ideas e incluso productos.
                    Además, podrán participar en iniciativas que promuevan la sostenibilidad, la protección del entorno y la
                    creación de nuevos negocios.
                  </p>
              
              <div className='store-container'>
                <a href='https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es_419'
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                  aria-label="Descargar desde Google Play"
                >
                  <img className='img-store' src={googlePlay} alt="Google Play Store" />
                </a>
                <a href='https://apps.apple.com/us/app/ecoideas-app/id6475202942'
                  style={{ textDecoration: 'none' }}
                  target='_blank'
                  aria-label="Descargar desde Apple Store"
                >
                  <img className='img-store' src={appleStore} alt="Apple App Store" />
                </a>
              </div>
          </div>
       

      </div>
     
   
  )
}
export default OurPlatforms