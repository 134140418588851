import React, { useEffect } from 'react'
import Section from '../Section/Section'
import ecotienda from '../../assets/img/Caramaster.svg'
import { CardEco } from '../Card/CardEco'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getEcotiendas } from '../../redux/actions/EcotiendasActions'
import phoneicon from '../../assets/img/icons/phone.svg'
import ubicationicon from '../../assets/img/icons/ubication-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../Button/Button'
import style from './EcoTienda.module.css'
import EcoMastersCards from '../Card/MCards'
import form from '../../docs/FORMATO_POSTULACION_ECOMASTER.pdf'


export const EcoTienda = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEcotiendas());
  }, []);
  const { ecotiendas } = useSelector((state) => state.ecotiendas)
  
  const descargarDocumentos = (urls) => {
    urls.forEach(url => {
        const link = document.createElement('a');
        link.href = url;
        link.download = url.substring(url.lastIndexOf('/') + 1);
        link.click();
    });
}
const urlsADescargar = [
    form,
];
  
  console.log(ecotiendas);
  return (
    <div>

      <div className={style.imgContainer}>

        <img src={ecotienda} className={style.img} alt='person looking gloseries' />
        
        <div className={style.imgInfo}>
         <h1 className={style.imgTitle}>¿QUE SON LOS ECOMASTER?</h1>
          <p >
            Emprendedores Conscientes que Orgánicamente influencian su comunidad,
            siendo Maestros Ambientales que cuidan el planeta, 
            cuya filosofía de vida busca Sustentablemente Transformar hábitos de consumo convencionales en hábitos Ecológicos,
            Responsables, Sostenibles y que mediante un modelo de distribución local de los productos biodegradables Biotú disminuyen la huella de carbono y protegen los ecosistemas.        
          </p>  

          <div className='d-flex flex-row flex-lg-row gap-1 '>
            
            <Button texto={"POSTULATE"} 
              style={{ border: '#a3d8b4',  
                      display: 'flex', 
                      flexDirection: 'row', 
                      padding: '0%', 
                      justifyContent: 'left'
                    }} 
              onClick={() => descargarDocumentos(urlsADescargar)}/>
            
            <li className={style.formato}>Diligencia el formulario, adjunta los documentos requeridos y 
              envíalo al correo servicioalcliente@biotu.com.co</li>
          </div>
        </div>
      </div>
      <Section text1={'ECOMASTERS PIONEROS'}  />
      <div className='notice_list'>
        { /* ecotiendas?.map((element) => (
          <CardEco
            key={element._id}

            img={element.picture && element.picture.url && element.picture.url.small}
            name={element.socialReason}
            adress={element?.ubication && element.ubication.businessAddress}
            phone={element?.businessPhone}
          />
        )) /* Cards de las ecotiendas */}
        <EcoMastersCards />
      </div>
      <Section />
    </div>
  )
}
