import React, { useState } from "react";
import circle from '../../assets/img/circulogris.png';
import lessCIrcle from '../../assets/img/lessCIrcle.png';
import marketplaceAdd from '../../assets/img/Marketplace-add.png';
import plusCircle from '../../assets/img/plusCircle.png';
import "./CardList.css";
import style from './CardListProducts.module.css';
import { addToBag, changeQuantity } from '../../redux/actions/MarketPlaceActions';
import { useDispatch } from 'react-redux';
import overlayImage from '../../assets/img/overlay.svg';
import LazyImage from "./LazyImage";
import modalStyle from '../../containers/ShowCase/ModalStyle.module.css';

const CardListProducts = ({
  zIndex = 0,
  addBag,
  maxQuantity = 0,
  productBag,
  product = [],
  pricePoints = '50',
  options = [],
  picture = '',
  text,
  onClick = () => { },
  onClickPicture = () => { }
}) => {

  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(productBag ? (productBag.quantity) : 1);
  const [isHover, setIsHover] = useState(false);

  const onClickPlusBtn = () => {
    if (maxQuantity > quantity) {
      setQuantity(parseInt(quantity) + 1);
    }
  }

  const onClickMinusBtn = () => {
    if (quantity >= 1) {
      setQuantity(parseInt(quantity) - 1);
    }
  }

  return (
    <div style={{ zIndex: `${zIndex}` }} className={`${style.content_card_list} grow-product-card`}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
    >

      <div className={`${style.content_img_product}`} onClick={onClickPicture}>
        <LazyImage 
          alt="product" 
          className={`${style.img_product}`} 
          onError={(e) => (e.target.src = circle)} 
          src={picture} 
          onClick={onClickPicture}
        />

        {/* Imagen de superposición siempre visible */}
        <img className={style.overlayImage} src={overlayImage} alt="Overlay" />

      </div>

      <div className={`${style.content_product_name}`} onClick={onClickPicture}>
        <div className={`${style.product_name}`}>
          {text}
        </div>
      </div>

      {
        isHover &&
        <div className={`${style.information_container}`}>
          <div className={`${style.price_text}`}>
            $ {product.price}
          </div>
          <div className={`${style.quantity_container}`}>
            <span>

              <div className={`${style.btn_quantity_container}`}>

                <div className={`${style.quantity_product_warning}`}>Stock: {maxQuantity}</div>

                <div className={`${style.quantity_product_container}`}>

                  <img
                    className={`${style.quantity_icon}`}
                    onClick={() => {
                      onClickMinusBtn()
                    }}
                    src={lessCIrcle} alt=""
                  />
                  <input
                    type={"text"}
                    value={quantity}
                    onChange={e => {
                      if (Number.isInteger((parseInt(e.target.value, 10)))) {
                        if (e.target.value <= maxQuantity && e.target.value >= 0) {
                          setQuantity(e.target.value);
                        }
                      } else if (e.target.value === '') {
                        setQuantity(e.target.value);
                      }
                    }}
                    className={`${style.quantity_product}`}
                  />
                  <img
                    className={`${style.quantity_icon}`}
                    onClick={() => {
                      onClickPlusBtn()
                    }} src={plusCircle} alt="" />
                </div>

                <div className={`${style.quantity_product_title}`}>Cantidad</div>

              </div>
            </span>
            <div>
              <button
                className={`${style.marketplace_add} animate__animated animate__bounceIn`}
                onClick={() => {
                  dispatch(addToBag(product));
                  dispatch(changeQuantity(product, quantity));
                }}
              >
                <img style={{ height: '28px' }} src={marketplaceAdd} alt="Agregar" />
                <span className={`${style.marketplace_add_text}`}>Agregar</span>
              </button>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default CardListProducts;
